import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DownArrow } from '@/assets'
import styles from './style.module.less'
import classNames from 'classnames'

const LANGS = [
  // { title: '中文', key: 'zh' },
  { title: 'English', key: 'en_US' },
  { title: '繁體中文', key: 'zh_TW' }
]

const LangChange: React.FC = () => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18n?.languages[0] || 'en_US')
  useEffect(() => {
    document.documentElement.lang = i18n?.languages[0] || 'en_US'
    setLang(i18n?.languages[0] || 'en_US')
  }, [])

  const handleLangChange = useCallback(
    (lang: string) => {
      if (i18n.language === lang) {
        return
      }
      i18n.changeLanguage(lang)
      document.documentElement.lang = lang
      setLang(lang)
    },
    [i18n]
  )

  const title = useMemo(() => {
    return LANGS.find((l) => l.key === lang)?.title
  }, [lang])

  return (
    <div className={styles.lang}>
      <div>{title}</div>
      <img className={styles.arrow} src={DownArrow} alt="down-arrow" />
      <div className={styles.langSelect}>
        {LANGS.map(({ key, title }) => {
          return (
            <div
              key={key}
              onClick={() => handleLangChange(key)}
              className={classNames(
                styles.langItem,
                lang === key && styles.active
              )}
            >
              {title}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LangChange
