import { errorNotice, notice } from '@/common/tip'
import { checkEmailValidate } from '@/common/verify'
import { WalletApi } from '@/services/wallet'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Config = {
  duration?: number
  type: string
}

function useSendEmail(config: Config) {
  const { duration = 60, type } = config
  const [time, setTime] = useState(duration)
  const timer = useRef<NodeJS.Timer>()
  const { t } = useTranslation()

  const sendEmail = async (email: string, shouldExist: boolean) => {
    if (!email) {
      notice(t('login.inputEmail'))
      return
    }
    const validate = await checkEmailValidate(email, shouldExist)
    if (!validate) {
      return
    }
    setTime((t) => t - 1)
    timer.current = setInterval(() => {
      setTime((t) => t - 1)
    }, 1000)
    const codeRes = await WalletApi.getCodeFromEmail(email, type)
    if (codeRes.data.code !== 0) {
      errorNotice(codeRes.data.message)
    }
  }

  useEffect(() => {
    if (time === 0) {
      clearInterval(timer.current)
      setTime(duration)
    }
  }, [time])

  useEffect(() => {
    return () => clearInterval(timer.current)
  }, [])

  const sendText =
    time < duration ? `${t('personal.sent')}(${time})` : t('personal.sendCode')

  const sendAble = time === duration

  return { time, sendEmail, sendText, sendAble }
}

export default useSendEmail
