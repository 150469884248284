import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import Module from '@/components/Module'
import { DownArrow } from '@/assets'
import classNames from 'classnames'

type Props = {
  title: string
  more?: boolean
  onClickMore?: () => void
  className?: string
}

const TitleModule: React.FC<PropsWithChildren<Props>> = ({
  title,
  more = true,
  onClickMore,
  children,
  className = ''
}) => {
  return (
    <Module className={classNames(styles.titleModule, className)}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {more && (
          <div className={styles.more} onClick={onClickMore}>
            查看更多 <img src={DownArrow} />
          </div>
        )}
      </div>
      <div>{children}</div>
    </Module>
  )
}

export default React.memo(TitleModule)
