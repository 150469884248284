import React, { useState } from 'react'
import styles from './style.module.less'
import Dropdown from '@/components/Dropdown'
import { DownArrow } from '@/assets'
import { Radio } from 'antd'
import classNames from 'classnames'
import Select from '@/components/Select'

type Props = {
  label?: string
  className?: string
  placeholder?: string
  value?: string
}

function Form() {
  return (
    <div className={styles.form}>
      <div className={styles.title}>选择地址来源</div>
      <div className={styles.formItem}>
        <div className={styles.formLabel}>地址类型</div>
        <Radio.Group>
          <Radio value={1}>交易平台地址</Radio>
          <Radio value={2}>钱包地址</Radio>
        </Radio.Group>
      </div>
      <div className={styles.formItem}>
        <Select options={[]} label="选择交易平台" placeholder="请选择" />
      </div>
      <div className={styles.tip}>
        *请注意，“地址来源”是为了更方便用户对地址进行标记的，所以您在填写时请务必仔细检查填写的地址信息与手币平台信息是否一致。
      </div>
    </div>
  )
}

const AddressSelect: React.FC<Props> = ({
  label,
  className,
  placeholder,
  value
}) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <Dropdown
        onOpenChange={(open) => setOpen(open)}
        trigger={['click']}
        open={open}
        dropdownRender={() => {
          return <Form />
        }}
      >
        <div className={styles.select} onClick={() => setOpen((o) => !o)}>
          <div className={value ? styles.selected : ''}>
            {value || placeholder}
          </div>
          <img
            src={DownArrow}
            className={classNames(styles.arrow, open && styles.openedArrow)}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default React.memo(AddressSelect)
