import React from 'react'
import styles from './style.module.less'
import { useUserInfo } from '@/store/useUserInfo'
import InputBox from '@/components/InputBox'
import Select from '@/components/Select'
import { LabelDatePicker } from '@/components/DatePicker'
import { Address } from '../components'
import { Button } from '@/components/Button'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BaseInfo from './BaseInfo'
import { checkUsername } from '@/common/login'
import { WalletApi } from '@/services/wallet'
import { errorNotice, notice } from '@/common/tip'
import { Form } from 'antd'
import dayjs from 'dayjs'

// 使用dayjs设置datepicker的初始值时，编辑日期时可能报错，通过以下方法解决
// 参考链接：https://github.com/ant-design/ant-design/issues/26190
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import Medias from './Medias'

dayjs.extend(weekday)
dayjs.extend(localeData)

const { LabelInput } = InputBox

const Personal: React.FC = () => {
  const { info, saveUserInfo } = useUserInfo()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  async function updateInfo() {
    const { userName, gender, birthdate } = form.getFieldsValue()
    const birthday = birthdate.valueOf()
    if (!checkUsername(userName, 2)) {
      return
    }
    const res = await WalletApi.updateAccountInfo({
      // 用户名如果没改，则不传或传空字符串
      userName: userName === info!.userName ? '' : userName,
      birthdate: birthday,
      gender: gender
    })
    if (res.data.code === 0) {
      notice(t('personal.saveSuccess'), 'success')
      // 修改成功更新用户信息到 store 及 sessionStorage
      saveUserInfo({ ...info!, userName, gender, birthday })
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <Module className={styles.personal}>
      <div className={styles.title}>{t('personal.personal')}</div>
      <div className={styles.baseInfo}>
        <Form form={form} onFinish={updateInfo}>
          <BaseInfo />
          <div className={styles.form}>
            <Form.Item name="userName" noStyle initialValue={info?.userName}>
              <LabelInput
                label={t('profile.username')}
                placeholder={t('profile.usernameRequired')}
              />
            </Form.Item>
            <LabelInput
              label={t('personal.walletAddress')}
              disabled
              defaultValue={info?.address}
            />
            <Form.Item name="gender" noStyle initialValue={info?.gender}>
              <Select
                label={t('normal.gender')}
                options={[
                  { value: 0, label: t('normal.male') },
                  { value: 1, label: t('normal.female') },
                  { value: 2, label: t('normal.unknown') }
                ]}
              />
            </Form.Item>
            <Form.Item
              name="birthdate"
              noStyle
              initialValue={info?.birthday ? dayjs(+info.birthday) : undefined}
            >
              <LabelDatePicker
                label={t('normal.birthday')}
                placeholder={t('normal.unknown')}
              />
            </Form.Item>
            {/* <Form.Item name="gender" noStyle>
              <LabelInput label="邀请绑定" placeholder="未绑定" />
            </Form.Item> */}
          </div>
          {/* <div className={styles.withdrawCtn}>
          <div className={styles.title}>收款方式</div>
          <div className={styles.withdrawHeader}>
            <div className={styles.subTitle}>提现地址管理</div>
            <ProfileButton
              onClick={() => history.push('/profile/address-manage')}
            >
              管理
            </ProfileButton>
          </div>
          <div className={styles.addresses}>
            <Address />
            <Address />
          </div>
        </div> */}
          <div className={styles.mediaInfo}>
            <div className={styles.title}>{t('profile.bindMedia')}</div>
            <Medias />
          </div>
          <Button className={styles.btn} type="submit" disabled={!info}>
            {t('profile.save')}
          </Button>
        </Form>
      </div>
    </Module>
  )
}

export default React.memo(Personal)
