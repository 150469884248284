import { WalletApi } from '@/services/wallet'
import { checkEmail, rsaEncrypt } from '@/utils/utils'
import i18next from 'i18next'
import { errorNotice, notice } from './tip'

const checkEmailFormat = (email: string) => {
  if (!email) {
    notice(i18next.t('login.email'), 'error')
    return false
  }
  if (!checkEmail(email)) {
    notice(i18next.t('login.emailFormart'), 'error')
    return false
  }
  return true
}

const checkEmailValidate = async (
  email: string,
  shouldExist: boolean
): Promise<boolean> => {
  const check = checkEmailFormat(email)
  if (!check) {
    return false
  }
  const res = await WalletApi.checkMailRegi({
    email: rsaEncrypt(email) as string
  })
  if (res.data.code !== 0) {
    errorNotice(res.data.message)
    return false
  }
  if (res.data.data !== shouldExist) {
    const text = res.data.data ? 'login.hasRegi' : 'personal.emailNot'
    notice(i18next.t(text), 'error')
    return false
  }
  return true
}

export { checkEmail, checkEmailValidate }
