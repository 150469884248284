import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { InputProps } from '@/common/types'
import Input from '@/components/Input'
import { SearchIcon } from '@/assets'

type Props = InputProps

const SearchInput: React.FC<Props> = ({ className, ...others }) => {
  return (
    <div className={classNames(styles.searchInput, className)}>
      <Input className={styles.input} {...others} />
      <img src={SearchIcon} className={styles.icon} />
    </div>
  )
}

export default React.memo(SearchInput)
