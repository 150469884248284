import React from 'react'
import styles from './style.module.less'
import { NoDataDark } from '@/assets'
import classNames from 'classnames'

type NoDataProps = {
  text?: string
  className?: string
}

const NoData = ({ text = 'no data', className = '' }: NoDataProps) => {
  return (
    <div className={classNames(styles.noData, className)}>
      <img src={NoDataDark} className={styles.noDataImg} />
      <div>{text}</div>
    </div>
  )
}

export default NoData
