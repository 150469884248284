import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { Page, TitleModule } from '../components'
import { NFT_MANAGE } from '@/constants/profile'
import { Space, TableProps } from 'antd'
import { ProfileButton } from '@/components/Button'
import Item from './Item'
import Switch from './Switch'
import { ProfileTable } from '@/components/Table'
import ColorfulText from '@/components/ColorfulText'
import { NFT as NFTType, UserAPI } from '@/services/userAPI'
import { errorNotice } from '@/common/tip'
import { getLangData, getMediaSrc } from '@/utils/utils'
import { DefaultNFT } from '../image'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'

const PAGE_SIZE = 12
const TYPE = 1

const NFT: React.FC = () => {
  const [open, setOpen] = useState(true)
  const [nfts, setNFTs] = useState<NFTType[]>([])
  const history = useHistory()
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [pageNo, setPageNo] = useState(1)

  function toDetail(id: string, tokenId: string) {
    history.push(`/profile/nft-detail?id=${id}&tokenId=${tokenId}`)
  }

  const columns: TableProps<NFTType>['columns'] = [
    {
      title: t('profile.icon'),
      dataIndex: 'showImageUrl',
      key: 'showImageUrl',
      align: 'center',
      width: 100,
      render(val, item) {
        const src = getMediaSrc(item.showImageUrl) || DefaultNFT
        return <img className={styles.avatar} src={src} />
      }
    },
    {
      title: t('profile.name'),
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: 200,
      render(val, item) {
        return getLangData(item.goodsNameI18n)
      }
    },
    {
      title: t('profile.price'),
      dataIndex: 'exchangePrice',
      key: 'exchangePrice',
      render(val, item) {
        return (
          <ColorfulText
            text={`${item.exchangePrice} ${t('gifr.pick')}`}
            colors={['#9A6BFE', '#FC78FF']}
            inline
            className={styles.price}
          />
        )
      }
    },
    {
      title: t('profile.operate'),
      dataIndex: 'operate',
      key: 'operate',
      align: 'center',
      width: 100,
      render(val, item) {
        return (
          <Space size={14}>
            <ProfileButton onClick={() => toDetail(item.id, item.tokenId)}>
              {t('profile.details')}
            </ProfileButton>
          </Space>
        )
      }
    }
  ]

  async function getNFT() {
    const res = await UserAPI.getNFTList({
      pageNo,
      pageSize: PAGE_SIZE,
      type: TYPE
    })
    if (res.data.code === 0) {
      setNFTs(res.data.data.records)
      setTotal(+res.data.data.total)
    } else {
      errorNotice(res.data.message)
    }
  }

  useEffect(() => {
    getNFT()
  }, [pageNo])

  return (
    <Page navigator={NFT_MANAGE}>
      <TitleModule
        title={t('profile.myNFT')}
        more={false}
        className={styles.module}
      >
        <Switch
          open={open}
          onClick={() => setOpen((o) => !o)}
          className={styles.switch}
        />
        <Empty data={nfts}>
          {open ? (
            <div className={styles.list}>
              {nfts.map((nft) => {
                return <Item key={nft.tokenId} data={nft} />
              })}
            </div>
          ) : (
            <ProfileTable
              dataSource={nfts}
              columns={columns}
              pagination={false}
              rowKey="tokenId"
            />
          )}
        </Empty>
        <div className={styles.paginationCtn}>
          <Pagination
            total={total}
            showSizeChanger={false}
            onChange={(page) => setPageNo(page)}
            current={pageNo}
            pageSize={PAGE_SIZE}
          />
        </div>
      </TitleModule>
    </Page>
  )
}

export default React.memo(NFT)
