import axios from 'axios'
import ERROR_STATUS_MAP from './errorStatus'
import { useUserInfo } from 'store/useUserInfo'
import i18next from 'i18next'
import { getJsonData } from '@/common/dataConvert'
import { DEVICE_TYPE, X_DEVICE_ID } from '@/config'
import { notify } from '@/common/tip'
import { getLocalItem, getSessionItem } from '@/common/storage'
import { taLogout } from '@/common/thinkingdata'
import { rsaEncrypt } from '@/utils/utils'

// 默认请求超时时间
const timeout = 60000

// 创建axios实例
export const service = axios.create({
  timeout,
  baseURL: '',
  //如需要携带cookie 该值需设为true
  withCredentials: true
})

const handleLogout = () => {
  taLogout()
  useUserInfo.getState().clear()
  notify(i18next.t('login.loginExpired'), i18next.t('login.loginExpiredTip'))
}

const errorHandle = (error: any) => {
  if (error.response?.status === 401) {
    handleLogout()
    return
  }
  let message = `${error.response?.status ?? ''} ${error.code}`
  let description = `${i18next.t('http.serverError')} \n ${error.config?.url}`
  if (/timeout/i.test(error.message)) {
    message = error.code
    description = `${i18next.t('http.timeout')} \n ${error.config?.url}`
  }
  if (ERROR_STATUS_MAP.has(error.response?.status)) {
    const errorDesc = ERROR_STATUS_MAP.get(error.response.status)!
    message = `${error.response.status} ${errorDesc.title}`
    description = `${i18next.t(errorDesc.detail)} \n ${error.config.url}`
  }
  notify(message, description)
}

function getBaseURL() {
  if (process.env.NODE_ENV === 'development') {
    return ''
  }
  if (process.env.isPre) {
    return 'https://meetapi-v3.meet48.xyz/meet48-api'
  }
  return process.env.isTestOrDev
    ? 'http://34.87.135.238'
    : 'https://meetapi-v2.meet48.xyz/meet48-api'
}

//统一请求拦截 可配置自定义headers 例如 language、token等
service.interceptors.request.use((config) => {
  const token = getSessionItem('token')

  config.url = getBaseURL() + config.url

  if (token) {
    config.headers.set('token', token)
  }

  config.headers.set('Content-Type', 'application/json;charset=UTF-8')
  if (config.headers.needEncrypt) {
    config.headers.set('Content-Type', 'text/plain')
    config.data = rsaEncrypt(JSON.stringify(config.data))
    config.headers.delete('needEncrypt')
  }
  if (config.headers.isFormData) {
    config.headers.set('Content-Type', 'multipart/form-data')
    config.headers.delete('isFormData')
  }
  config.headers.set('X-Custom-Device-Type', DEVICE_TYPE)
  config.headers.set('X-VersionCode', '1.0.0')

  const deviceId = getLocalItem(X_DEVICE_ID)

  if (typeof deviceId === 'string') {
    config.headers.set(X_DEVICE_ID, deviceId)
  }

  config.headers.set('Accept-Language', i18next.language)
  return config
})

service.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    errorHandle(error)
    return { data: { message: '' } } as any
  }
)
