import React, { useMemo, useRef, useState } from 'react'
import styles from './style.module.less'
import { ModalProps } from '@/components/Modal'
import { useTranslation } from 'react-i18next'
import { RegisterParams } from '..'
import { Arrow } from '@/assets'
import { Button } from '@/components/Button'
import { rsaEncrypt } from '@/utils/utils'
import { useInviteCode } from '@/hooks/useInviteCode'
import { errorNotice, notice } from '@/common/tip'
import classNames from 'classnames'
import { RESEND_TIME } from '@/constants/login'
import { WalletApi } from '@/services/wallet'
import { useUserInfo } from '@/store/useUserInfo'
import { TA_EVENT_MAP, taTrack } from '@/common/thinkingdata'
import { setSessionItem } from '@/common/storage'
import { RecaptchaActions, getGoogleRecaptchaToken } from '@/common/login'

type Props = ModalProps & {
  toRegister: () => void
  className?: string
  registerParams?: RegisterParams
  sendEmail: () => Promise<void>
  count: number
}

const VerifyCodeForm = ({
  toRegister,
  className = '',
  registerParams,
  sendEmail,
  count,
  onDismiss
}: Props) => {
  const { t, i18n } = useTranslation()
  const { inviteCode } = useInviteCode()

  const { save } = useUserInfo()

  const tip = useMemo(() => {
    if (i18n.language === 'en_US') {
      return (
        <div className={styles.tip}>
          Please enter the verification code sent to your email address
          <span className={styles.email}>{registerParams?.email}</span>
        </div>
      )
    }
    if (i18n.language === 'zh') {
      return (
        <div className={styles.tip}>
          请输入已发送至您的邮箱（
          <span className={styles.email}>{registerParams?.email}</span>
          ）中的验证码
        </div>
      )
    }
    return (
      <div className={styles.tip}>
        請輸入已寄送至您的信箱（
        <span className={styles.email}>{registerParams?.email}</span>
        ）中的驗證碼
      </div>
    )
  }, [i18n.language, registerParams])

  const [value, setValue] = useState('')

  const [activeIndex, setActiveIndex] = useState(0)

  const codes = useMemo(() => {
    return [...value.split(''), ...new Array(6 - value.length).fill('')]
  }, [value])

  const inputRef = useRef<HTMLInputElement>(null)

  async function submit() {
    if (!registerParams) {
      return
    }
    try {
      const token = await getGoogleRecaptchaToken(RecaptchaActions.register)
      if (!token) {
        return
      }
      const res = await WalletApi.registerWeb2({
        code: value,
        mail: rsaEncrypt(registerParams.email) as string,
        password: rsaEncrypt(registerParams.pwd) as string,
        recommenderCode: inviteCode,
        registrationType: registerParams.registrationType,
        userName: rsaEncrypt(registerParams.username) as string,
        googleActionType: RecaptchaActions.register,
        googleRecaptchaToken: token
      })
      if (res.data.code === 0) {
        notice(t('login.regiToLog'), 'success')

        // 注册成功存入用户信息到store及sessionStorage
        save(res.data.data)

        setSessionItem('login-time', `${Date.now()}`)
        onDismiss!()
      } else {
        errorNotice(res.data.message)
      }
      taTrack(TA_EVENT_MAP.register, { result: res.data.message })
    } catch (error: any) {
      errorNotice(error)
    }
  }

  function changeHandle(val: string) {
    if (!/^[0-9]*$/.test(val)) {
      return
    }
    setValue((prevVal) => {
      if (prevVal.length > val.length) {
        // 减少时（回退）
        if (activeIndex <= prevVal.length - 1) {
          return prevVal.slice(0, activeIndex) + prevVal.slice(activeIndex + 1)
        } else {
          setActiveIndex(Math.max(prevVal.length - 1, 0))
          return val
        }
      } else {
        if (activeIndex > prevVal.length - 1) {
          setActiveIndex(Math.min(val.length, 5))
          return val
        } else {
          setActiveIndex(Math.min(5, activeIndex + 1))
          return (
            prevVal.slice(0, activeIndex) +
            val[val.length - 1] +
            prevVal.slice(activeIndex + 1)
          )
        }
      }
    })
  }

  function codeClick(index: number) {
    setActiveIndex(index)
    inputRef.current?.focus()
  }

  function resendHandle() {
    if (count < RESEND_TIME) {
      return
    }
    sendEmail()
  }

  return (
    <div className={className}>
      <div className={styles.title}>{t('login.verify')}</div>
      {tip}
      <div className={styles.enterConfirmCode}>
        {t('login.enterConfirmCode')}
      </div>
      <div className={styles.codes}>
        {codes.map((code, index) => {
          return (
            <div
              className={classNames(
                styles.code,
                activeIndex === index && styles.activeCode
              )}
              key={index}
              onClick={() => codeClick(index)}
            >
              {code}
            </div>
          )
        })}
      </div>
      <input
        className={styles.input}
        maxLength={6}
        value={value}
        onChange={(e) => changeHandle(e.target.value)}
        ref={inputRef}
        onBlur={() => setActiveIndex(-1)}
        autoFocus
      />
      <div className={styles.btns}>
        <div className={styles.arrowCtn} onClick={toRegister}>
          <img src={Arrow} className={styles.arrow} />
        </div>
        <Button
          disabled={value.length < 6}
          className={styles.submit}
          onClick={submit}
        >
          {t('login.register')}
        </Button>
      </div>
      <div className={styles.link} onClick={resendHandle}>
        {t('login.resend')}
        {count !== 60 && `(${count})`}
      </div>
      <div className={styles.tips}>
        <div className={styles.tipTitle}>{t('login.tip')}</div>
        <div>{t('login.tip1')}</div>
        <div>{t('login.tip2')}</div>
      </div>
    </div>
  )
}

export default VerifyCodeForm
