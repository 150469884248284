import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import ModalWrapper from '../ModalWrapper'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'

type Props = {
  onDismiss?: () => void
  onOk?: () => void
  title: string
  hasBtn?: boolean
}

const ConfirmModal: React.FC<PropsWithChildren<Props>> = ({
  onDismiss,
  onOk,
  title,
  children,
  hasBtn = true
}) => {
  const { t } = useTranslation()
  return (
    <ModalWrapper title={title} onDismiss={onDismiss} width={400}>
      {children}
      {hasBtn && (
        <div className={styles.btnCtn}>
          <Button onClick={onOk} className={styles.btn}>
            {t('normal.ok')}
          </Button>
        </div>
      )}
    </ModalWrapper>
  )
}

export default React.memo(ConfirmModal)
