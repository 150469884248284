import React from 'react'
import styles from './style.module.less'
import { FileIcon } from '@/assets'
import InputBox from '@/components/InputBox'
import ModalWrapper from '@/components/ModalWrapper'
import { ModalProps } from '@/components/Modal'
import useModal from '@/hooks/useModal'
import Address from '../Address'

const { LabelInput } = InputBox

const AddressModal: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <ModalWrapper onDismiss={onDismiss} title="选择提现地址">
      <Address operational={false} />
      <Address operational={false} />
    </ModalWrapper>
  )
}

const ExistAddressSelect: React.FC = () => {
  const [openModal] = useModal(<AddressModal />)
  return (
    <div className={styles.existAddressSelect}>
      <LabelInput
        placeholder="输入地址"
        label="地址"
        inputExtra={
          <img src={FileIcon} className={styles.icon} onClick={openModal} />
        }
      />
    </div>
  )
}

export default React.memo(ExistAddressSelect)
