import React from 'react'
import styles from './style.module.less'
import { SETTING } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import InputBox from '@/components/InputBox'
import { ProfileButton } from '@/components/Button'
import { Space } from 'antd'
import { DeleteIcon, EditIcon, NoLoginImg } from '@/assets'
import { useHistory } from 'react-router-dom'
import useModal from '@/hooks/useModal'
import UnbindModal from './UnbindModal'
import { useUserInfo } from '@/store/useUserInfo'
import { useTranslation } from 'react-i18next'

const { LabelInput } = InputBox

const bondGoogle = true

const Setting: React.FC = () => {
  const { info } = useUserInfo()
  const history = useHistory()
  const { t } = useTranslation()
  const [openModal] = useModal(<UnbindModal />)

  return (
    <Page navigator={SETTING}>
      <TitleModule title={t('profile.accountSecurity')} more={false}>
        <div className={styles.form}>
          <LabelInput
            label={t('profile.email')}
            defaultValue={info?.email}
            disabled
            inputExtra={
              <ProfileButton
                className={styles.settingBtn}
                onClick={() => history.push('/profile/update-email')}
              >
                {t('profile.setting')}
              </ProfileButton>
            }
          />
          <LabelInput
            label={t('personal.updatePwd')}
            type="password"
            disabled
            inputExtra={
              <ProfileButton
                className={styles.settingBtn}
                onClick={() => history.push('/profile/update-password')}
              >
                {t('profile.setting')}
              </ProfileButton>
            }
          />
          {/* <LabelInput
            label="资金密码"
            type="password"
            placeholder="点击设置以设置密码"
            inputExtra={
              <ProfileButton
                className={styles.settingBtn}
                onClick={() => history.push('/profile/set-funds-password')}
              >
                设置
              </ProfileButton>
            }
          /> */}
          {/* {bondGoogle ? (
            <div>
              <div className={styles.label}>Google身份验证器</div>
              <div className={styles.content}>
                <div className={styles.left}>
                  <Space size={14}>
                    <img src={NoLoginImg} className={styles.avatar} />
                    <div className={styles.info}>
                      <div className={styles.name}>Authenticator</div>
                      <div>添加于：2024/03/25</div>
                    </div>
                  </Space>
                </div>
                <Space size={30}>
                  <img
                    src={EditIcon}
                    className={styles.icon}
                    onClick={() =>
                      history.push('/profile/update-authenticator')
                    }
                  />
                  <img
                    src={DeleteIcon}
                    className={styles.icon}
                    onClick={openModal}
                  />
                </Space>
              </div>
            </div>
          ) : (
            <LabelInput
              label="Google身份验证器"
              type="password"
              placeholder="未绑定"
              inputExtra={
                <ProfileButton
                  className={styles.settingBtn}
                  onClick={() => history.push('/profile/set-authenticator')}
                >
                  绑定
                </ProfileButton>
              }
            />
          )} */}
        </div>
        {/* <div className={styles.logoffCtn}>
          <div className={styles.logoff}>账户注销</div>
        </div> */}
      </TitleModule>
    </Page>
  )
}

export default React.memo(Setting)
