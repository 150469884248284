import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import { ButtonProps } from '@/common/types'
import classNames from 'classnames'
import Diamond from '@/assets/images/red-envelopes/diamond.png'

type Props = ButtonProps & {
  btnType?: 'gold' | 'silver' | 'bronze'
}

const RedEnvelopeButton: React.FC<PropsWithChildren<Props>> = ({
  btnType = 'gold',
  children,
  className,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={classNames(
        styles.redEnvelopeButton,
        btnType === 'gold' && styles.gold,
        btnType === 'silver' && styles.sliver,
        btnType === 'bronze' && styles.bronze,
        className
      )}
    >
      {children} <img src={Diamond} className={styles.diamond} />
    </button>
  )
}

export default React.memo(RedEnvelopeButton)
