import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAccount, useDisconnect } from 'wagmi'
import { NoLoginImg } from '@/assets'
import { formatAddress, getEllipsisText } from '@/utils/utils'
import { useUserInfo } from 'store/useUserInfo'
import { useAssets } from 'store/useAssets'
import { UserInfo, WalletApi } from '@/services/wallet'
import { IMG_BASE_URL } from '@/config'
import styles from './style.module.less'
import { DownArrow } from '@/assets'
import { errorNotice, notice, notify } from '@/common/tip'
import { taEnterPageTrack, taLogout } from '@/common/thinkingdata'
import { WEB2_TYPE, WEB3_TYPE } from '@/constants/user'

interface AccountProp {
  userInfo: UserInfo
}

const AccountButton: React.FC<AccountProp> = ({ userInfo }) => {
  const { t } = useTranslation()

  const { clearAssets } = useAssets()

  const { isConnected, address } = useAccount()
  const { disconnectAsync } = useDisconnect()
  const history = useHistory()
  const { userAvatar, clear: clearUserInfo } = useUserInfo()

  const account = useMemo(() => {
    if (userInfo.userName) {
      return getEllipsisText(userInfo.userName, 6)
    }
    const addr = userInfo.type === WEB2_TYPE ? userInfo.address : address
    return formatAddress(addr!, 6, -4)
  }, [userInfo])

  //退出登录
  const handleLoginOut = async () => {
    try {
      const res = await WalletApi.logOut({ accountId: userInfo.accountId })
      if (res.data.code === 0) {
        notice(t('login.logoutSuccess'), 'success')

        if (userInfo.type === WEB3_TYPE) {
          await disconnectAsync()
        }

        clearUserInfo() // 退出登录清除用户信息
        clearAssets()
        history.push('/')
        taLogout()
      } else {
        notify(t('login.logoutErr'), res.data.message)
      }
    } catch (error) {
      errorNotice(error)
    }
  }

  function to(path: string) {
    history.push(path)
    taEnterPageTrack(path, '导航栏')
  }

  const headImg = useMemo(() => {
    const src = userAvatar ? `${IMG_BASE_URL}/${userAvatar}` : NoLoginImg
    return <img src={src} className={styles.avatar} alt="" />
  }, [userAvatar])

  return (
    <div className={styles.ctn}>
      {headImg}
      <div className={styles.account}>{account}</div>
      <img className={styles.arrow} src={DownArrow} alt="down-arrow" />
      <div className={styles.dropdownCtn}>
        <div className={styles.dropdown}>
          <div onClick={() => to('/personal')} className={styles.item}>
            {t('personal.personal')}
          </div>
          <div
            onClick={() => to('/profile/video-manage')}
            className={styles.item}
          >
            {t('dazzle.myCreations')}
          </div>
          <div onClick={() => to('/profile/nft')} className={styles.item}>
            {t('profile.myNFT')}
          </div>
          <div onClick={() => to('/profile/items')} className={styles.item}>
            {t('profile.myItems')}
          </div>
          <div
            onClick={() => to('/profile/point-record')}
            className={styles.item}
          >
            {t('profile.orderRecord')}
          </div>
          <div onClick={() => to('/profile/setting')} className={styles.item}>
            {t('profile.setting')}
          </div>
          <div onClick={handleLoginOut} className={styles.signOut}>
            {t('login.logOut')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountButton
