import {
  BIND_TASKS,
  PROMOTIONAL_TASKS,
  TWITTER_TASKS,
  VISITING_TASKS
} from '@/constants/task'
import { Lang, LangData, Obj } from '@/common/types'
import dayjs from 'dayjs'
import { getSessionItem } from '@/common/storage'
import i18next from 'i18next'
import { IMG_BASE_URL } from '@/config'

//验证邮箱
export const checkEmail = (value: string) => {
  return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(
    value
  )
}

//公钥加密
export const rsaEncrypt = (text: string) => {
  const publicKey = getSessionItem('PublicKey') || ''
  return window.__rsaEncrypt?.(publicKey, text)
}

export const rsaDecrypt = (data: string) => {
  const publicKey = getSessionItem('PublicKey') || ''
  return window.__rsaDecrypt?.(publicKey, data)
}

export const formatAddress = (address: string, start = 6, end = -6): string => {
  return address ? address.slice(0, start) + '...' + address.slice(end) : ''
}

//请求参数添加 'chain'：'BNB'
export const paramAddChain = (params: Obj) => {
  params['chain'] = 'BNB'
  return params
}

//除4取余
export const getRemainder = (num: number) => {
  const count = (num + 1) % 4
  return count === 0 ? 4 : count
}

export const handleTaskColor = (key: string, alpha: number) => {
  switch (key) {
    case 'webTasks':
      return `rgba(222, 23, 213,${alpha})`
    case PROMOTIONAL_TASKS:
      return `rgba(174, 26, 235,${alpha})`
    case VISITING_TASKS:
      return `rgba(244, 156, 152,${alpha})`
    case BIND_TASKS:
      return `rgba(215, 93, 74,${alpha})`
    case TWITTER_TASKS:
      return `rgba(253, 215, 51,${alpha})`
    default:
      return `rgba(222, 23, 213,${alpha})`
  }
}

export const getSearchParams = () => {
  const hash = window.location.hash
  const search = window.location.search || hash.slice(hash.indexOf('?'))
  const searchParamsStr = search.slice(1)
  return Object.fromEntries(new URLSearchParams(searchParamsStr))
}

// 检查对象的值每个值是否为 falsy，如果都是返回true
export const checkObjFalsy = (obj: Obj) => {
  const entries = Object.entries(obj)
  return entries.every(([, v]) => !v)
}

export const debouncePromise = <T>(
  fn: (...rest: any[]) => Promise<T>,
  time = 300
) => {
  let timer: NodeJS.Timer
  let promise: Promise<T> | undefined
  let res: ((value: T | PromiseLike<T>) => void) | undefined
  const timeoutHandle = (...args: any[]) => {
    res!(fn(...args))
    promise = undefined
    res = undefined
  }
  return (...args: any[]) => {
    if (!promise) {
      promise = new Promise(function (resolve) {
        res = resolve
        timer = setTimeout(() => timeoutHandle(...args), time)
      })
    } else {
      clearTimeout(timer)
      timer = setTimeout(() => timeoutHandle(...args), time)
    }
    return promise
  }
}

function getImageResolution(
  url: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const img = new Image()

    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height
      })
    }

    img.onerror = function () {
      console.log('Failed to load image')
      resolve({ width: 0, height: 0 })
    }

    img.src = url
  })
}

export const getImageFileResolution = async (file: File) => {
  const windowURL = window.URL || window.webkitURL
  const url = windowURL.createObjectURL(file)
  const data = await getImageResolution(url)
  windowURL.revokeObjectURL(url)
  return data
}

export const getHourTime = (ms: number) => {
  const seconds = Math.floor(ms / 1000)
  const hour = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds - hour * 3600) / 60)
  const second = seconds % 60
  return {
    hour: `${hour}`.padStart(2, '0'),
    minute: `${minute}`.padStart(2, '0'),
    second: `${second}`.padStart(2, '0')
  }
}

export const getDetailTime = (timestamp: number, hasZero = true) => {
  const d = new Date(timestamp)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const date = d.getDate()
  const hour = d.getHours()
  const minute = d.getMinutes()
  const second = d.getSeconds()
  const millisecond = d.getMilliseconds()
  return {
    year: `${year}`,
    month: hasZero ? `${month}`.padStart(2, '0') : `${month}`,
    date: hasZero ? `${date}`.padStart(2, '0') : `${date}`,
    hour: hasZero ? `${hour}`.padStart(2, '0') : `${hour}`,
    minute: hasZero ? `${minute}`.padStart(2, '0') : `${minute}`,
    second: hasZero ? `${second}`.padStart(2, '0') : `${second}`,
    millisecond: hasZero ? `${millisecond}`.padStart(3, '0') : `${millisecond}`
  }
}

type FormatConfig = {
  format?: string
  offset?: number
}

export function formatTime(timestamp: number, config: FormatConfig = {}) {
  const { format = 'YYYY-MM-DD HH:mm:ss', offset = 0 } = config
  return dayjs(timestamp).add(offset, 'hour').format(format)
}

export function openWindow(link: string, target = '_blank') {
  if (!link) {
    console.log('链接为空')
    return
  }
  window.open(link, target)
}

/**
 * 获取字符串长度，中文为2，其他为1
 */
export function getStringLength(str: string) {
  const zhReg = /[\u4e00-\u9fa5]+/g
  const zhWords = str.match(zhReg) ?? ([] as string[])
  const zhLen = zhWords.reduce((prev, word) => prev + word.length, 0)
  const otherLen = str.length - zhLen
  const lastLen = zhLen * 2 + otherLen
  return lastLen
}

/**
 * 获取被截短的字符串
 */
export function getEllipsisText(str: string, limit: number) {
  const len = getStringLength(str)
  if (len <= limit) {
    return str
  }
  let result = ''
  let i = 0
  while (getStringLength(result) < limit) {
    result += str[i]
    i++
  }
  return `${result}${str.length === result.length ? '' : '...'}`
}

enum Month {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11
}

function getDay(day: number) {
  if (day === 1) {
    return '1st'
  }
  if (day === 2) {
    return '2nd'
  }
  if (day === 3) {
    return '3rd'
  }
  return `${day}th`
}

export function getEnTime(timestamp: number) {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = Month[date.getMonth()]
  const day = getDay(date.getDate())
  const hour = `${date.getHours()}`.padStart(2, '0')
  const minute = `${date.getMinutes()}`.padStart(2, '0')
  return `${month} ${day} ${hour}:${minute}`
}

export function setCookie(key: string, value: string, validTime: number) {
  const expires = new Date(+new Date() + validTime).toUTCString()
  const cookie = `${key}=${value}; expires=${expires};`
  document.cookie = cookie
}

export const parseCookie = (cookie = document.cookie): Obj => {
  const items = cookie.split(';')
  return items.reduce((prev, item) => {
    const [key, value] = item.split('=')
    return { ...prev, [key.trim()]: value }
  }, {})
}

export function getCookie(key: string) {
  const cookies = parseCookie()
  return cookies[key] ?? ''
}

export function getLangData(data?: LangData) {
  if (!data) {
    return ''
  }
  return data[i18next.language as Lang]
}

export function padZero(num: number) {
  return `${num}`.padStart(2, '0')
}

export function getVideoDuration(duration: number) {
  const d = Math.floor(duration)
  const hour = Math.floor(d / 3600)
  const minutes = Math.floor((d - hour * 3600) / 60)
  const second = d % 60
  const ph = padZero(hour)
  const pm = padZero(minutes)
  const ps = padZero(second)
  if (d >= 3600) {
    return `${ph}:${pm}:${ps}`
  }
  return `${pm}:${ps}`
}

export function getVideoDurationByURL(url: string): Promise<number> {
  if (!url) {
    console.log('url not exist')
    return Promise.resolve(0)
  }
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = getMediaSrc(url)!
    video.addEventListener('loadedmetadata', () => {
      resolve(video.duration)
      video.remove()
    })
    video.addEventListener('error', () => {
      resolve(0)
      video.remove()
    })
    document.body.appendChild(video)
  })
}

export function getMediaSrc(src?: string) {
  if (!src) {
    return undefined
  }
  if (src.startsWith('http') || src.startsWith('//')) {
    return src
  }
  return `${IMG_BASE_URL}/${src}`
}

type EllipsisConfig = {
  head: number
  tail: number
  replaceText: string
}

export function ellipsisText(text = '', config: Partial<EllipsisConfig> = {}) {
  if (!text) {
    return ''
  }
  const { head = 6, tail = 6, replaceText = '...' } = config
  const reg = new RegExp(`(?<=\\w{${head}})\\w+(?=\\w{${tail}})`)
  return text.replace(reg, replaceText)
}

export async function readImageToDataURL(file: File): Promise<string> {
  if (!file) {
    return ''
  }
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = (event: any) => {
      const imageUrl = event.target.result
      resolve(imageUrl)
    }
    reader.onerror = (error: any) => {
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}

export function formatNumberWithCommas(number: number) {
  const numberParts = number.toString().split('.')
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return numberParts.join('.')
}

export function paddingTime(time?: number | null | string, replacer = '') {
  if (typeof time === 'string') {
    return time
  }
  if (typeof time === 'number') {
    return `${time}`.padStart(2, '0')
  }
  return replacer
}
