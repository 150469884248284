import React, { useState } from 'react'
import styles from './style.module.less'
import Dropdown from '@/components/Dropdown'
import { DownArrow } from '@/assets'
import classNames from 'classnames'
import InputBox from '@/components/InputBox'
import { CurrencyIcon } from '../../image'

const { SearchInput } = InputBox

type Props = {
  label?: string
  className?: string
  placeholder?: string
  value?: string
  onChange?: (val: string) => void
}

function Form() {
  return (
    <div className={styles.form}>
      <div className={styles.item}>
        <div className={styles.label}>选择币种</div>
        <SearchInput placeholder="搜索" />
      </div>
      <div className={styles.item}>
        <div className={styles.label}>热门</div>
        <div className={styles.currency}>
          <img src={CurrencyIcon} className={styles.currencyIcon} />
          <span className={styles.text}>USDT</span>
        </div>
        <div className={styles.currency}>
          <img src={CurrencyIcon} className={styles.currencyIcon} />
          <span className={styles.text}>USDT</span>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>币种列表</div>
        <div className={styles.list}>
          <div className={styles.line}>
            <img src={CurrencyIcon} className={styles.currencyIcon} />
            <div className={styles.texts}>
              <span className={styles.text}>USDT</span>
              <span>TetherUS</span>
            </div>
          </div>
          <div className={styles.line}>
            <img src={CurrencyIcon} className={styles.currencyIcon} />
            <div className={styles.texts}>
              <span className={styles.text}>USDT</span>
              <span>TetherUS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CurrencySelect: React.FC<Props> = ({
  label,
  className,
  placeholder = '请选择币种',
  value,
  onChange
}) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <Dropdown
        onOpenChange={(open) => setOpen(open)}
        trigger={['click']}
        open={open}
        dropdownRender={() => {
          return <Form />
        }}
      >
        <div className={styles.select} onClick={() => setOpen((o) => !o)}>
          <div className={value ? styles.selected : ''}>
            {value || placeholder}
          </div>
          <img
            src={DownArrow}
            className={classNames(styles.arrow, open && styles.openedArrow)}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default React.memo(CurrencySelect)
