import React, { useState, useMemo, useRef, useEffect } from 'react'
import styles from './style.module.less'
import { Eye, EyeInvisible } from '@/assets'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { destroyTip, showTip } from '@/common/tip'
import { WalletApi } from '@/services/wallet'
import { getEmailValid } from '@/common/login'
import { RESEND_TIME } from '@/constants/login'

type Props = {
  title: string
  value: string
  onChange: (v: string) => void
  hasSend?: boolean
  isPassword?: boolean
  extra?: React.ReactNode
  onKeyDown?: React.KeyboardEventHandler<Element>
  email?: string
}

const InputBox = ({
  title,
  isPassword = false,
  hasSend = false,
  extra,
  value,
  onChange,
  onKeyDown,
  email = ''
}: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  const [isFocus, setIsFocus] = useState(false)

  const [count, setCount] = useState(RESEND_TIME)
  const timer = useRef<NodeJS.Timer>()
  const hadSent = useRef(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (count === 0) {
      clearInterval(timer.current)
      setCount(RESEND_TIME)
    }
  }, [count])

  useEffect(() => {
    return () => clearInterval(timer.current)
  }, [])

  const type = useMemo(() => {
    if (!isPassword) {
      return 'text'
    }
    return showPassword ? 'text' : 'password'
  }, [isPassword, showPassword])

  const onSend = async () => {
    if (!getEmailValid(email)) {
      return
    }
    if (count !== RESEND_TIME) {
      return
    }
    destroyTip()
    setCount((c) => c - 1)
    timer.current = setInterval(() => {
      setCount((c) => c - 1)
    }, 1000)
    hadSent.current = true
    const res = await WalletApi.getCodeFromEmail(email, '2')
    if (res.data.code !== 0) {
      showTip(res.data.message)
    }
  }

  const sendText = useMemo(() => {
    if (count === RESEND_TIME) {
      return hadSent.current ? t('login.resend') : t('login.send')
    }
    return `${t('login.resend')}(${count})`
  }, [count])

  return (
    <div className={styles.inputBox}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {extra}
      </div>
      <div className={classNames(styles.inputCtn, isFocus && styles.focus)}>
        <input
          type={type}
          className={styles.input}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => setIsFocus(false)}
          onFocus={() => setIsFocus(true)}
          onKeyDown={onKeyDown}
        />
        {isPassword && (
          <img
            onClick={() => setShowPassword((v) => !v)}
            src={showPassword ? Eye : EyeInvisible}
            className={styles.eye}
          />
        )}
        {hasSend && (
          <div className={styles.send} onClick={onSend}>
            {sendText}
          </div>
        )}
      </div>
    </div>
  )
}

export default InputBox
