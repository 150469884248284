import React from 'react'
import styles from './style.module.less'
import ModalWrapper from '@/components/ModalWrapper'
import { Warning } from '@/assets'
import { Button } from '@/components/Button'
import classNames from 'classnames'
import { ModalProps } from '@/components/Modal'

const UnbindModal: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <ModalWrapper title="" hasClose={false} width={407}>
      <div className={styles.content}>
        <img src={Warning} className={styles.icon} />
        <div className={styles.title}>您确认要解绑身份验证器APP验证吗？</div>
        <div>
          解除身份验证器APP验证后，为确保您的资产安全提现将被禁用24小时。
        </div>
        <div>
          提现和其他操作需要两种安全验证方式。仅使用一
          种验证方式将使您的账户面临较高的风险。
        </div>
        <div className={styles.btns}>
          <Button className={styles.btn}>确认</Button>
          <Button
            className={classNames(styles.btn, styles.cancel)}
            onClick={onDismiss}
          >
            取消
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default React.memo(UnbindModal)
