import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import VideoControls from './VideoControls'
import { getMediaSrc } from '@/utils/utils'
import { EllipsisIconWhite } from '@/assets'
import { HomemadeVideo, MediaAPI } from '@/services/MediaAPI'
import { getStatus, getStatusText } from '@/common/dazzle'
import { errorNotice, notice } from '@/common/tip'
import { useModal } from '@/store/useModal'
import ConfirmModal from '../ConfirmModal'
import { useTranslation } from 'react-i18next'

type Props = {
  videoRef: React.RefObject<HTMLVideoElement>
  className?: string
  onEnded?: () => void
  isManage?: boolean
  info?: HomemadeVideo | null
  refresh?: () => void
  handleDelete?: () => void
}

const modalContentMap: Record<string, any> = {
  takeDown: {
    title: 'dazzle.visibleToMeOnly',
    content: 'dazzle.visibleToMeOnlyTip'
  },
  delete: { title: 'normal.delete', content: 'dazzle.deleteTip' }
}

const CustomVideo: React.FC<Props> = (props) => {
  const {
    videoRef,
    className,
    onEnded,
    isManage = false,
    info,
    refresh,
    handleDelete
  } = props
  const { t } = useTranslation()
  const { videoUrl, coverImageUrl, id } = info ?? {}
  const status = getStatus(info)
  const statusText = getStatusText(info)

  const realSrc = getMediaSrc(videoUrl)
  const realPoster = getMediaSrc(coverImageUrl)

  const { openAsync } = useModal()

  async function handleConfirm(operate: string) {
    if (operate === 'release') {
      handleOperate(operate)
      return
    }
    const texts = modalContentMap[operate]!
    const confirmed = await openAsync(
      <ConfirmModal title={t(texts.title)}>{t(texts.content)}</ConfirmModal>
    )
    if (confirmed) {
      handleOperate(operate)
    } else {
      console.log('cancel')
    }
  }

  async function handleOperate(operate: string) {
    const res = await MediaAPI.operateVideo(id!, operate)
    if (res.data.code === 0) {
      notice(t('dazzle.success'), 'success')
      if (operate === 'delete') {
        handleDelete?.()
      } else {
        refresh?.()
      }
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <div className={classNames(styles.customVideo, className)} id="video-ctn">
      <video
        ref={videoRef}
        src={realSrc}
        poster={realPoster}
        className={styles.video}
        onEnded={onEnded}
      ></video>
      <VideoControls videoRef={videoRef} className={styles.controls} id={id} />
      {isManage && (
        <div className={styles.header}>
          <div className={styles.status}>{t(statusText)}</div>
          {status !== 2 && (
            <div className={styles.operateCtn}>
              <img src={EllipsisIconWhite} className={styles.ellipsisIcon} />
              <div className={styles.operates}>
                {status === 1 && (
                  <div
                    className={styles.item}
                    onClick={() => handleConfirm('takeDown')}
                  >
                    {t('dazzle.visibleToMeOnly')}
                  </div>
                )}
                {status === 3 && (
                  <div
                    className={styles.item}
                    onClick={() => handleConfirm('release')}
                  >
                    {t('dazzle.videoPost')}
                  </div>
                )}
                {(status === 3 || status === 4 || status === 5) && (
                  <div
                    className={styles.item}
                    onClick={() => handleConfirm('delete')}
                  >
                    {t('normal.delete')}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(CustomVideo)
