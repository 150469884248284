import { service } from '../http'
import { PromiseListResponse, PromiseResponse, replaceToV2 } from '../common'
import {
  GetNFTDetailParams,
  GetNFTListParams,
  NFT,
  NFTDetail,
  OpenBlindBoxParams
} from './model'

const BASE_URL = '/user/api/v1'

export class UserAPI {
  // 分页查询用户NFT列表
  static async getNFTList(
    params: GetNFTListParams
  ): PromiseListResponse<{ records: NFT[] }> {
    return service.post(replaceToV2(`${BASE_URL}/nft/list`), params)
  }
  // 获取我的NFT详情
  static async getNFTDetail(
    params: GetNFTDetailParams
  ): PromiseResponse<NFTDetail> {
    return service.get(`${BASE_URL}/nft/getDetail`, { params })
  }
  // 手动开启盲盒
  static async openBlindBox(
    params: OpenBlindBoxParams
  ): PromiseResponse<NFT[]> {
    return service.post(`${BASE_URL}/nft/openBlindBox`, params)
  }
}
