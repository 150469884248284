import React from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { NFT_MANAGE } from '@/constants/profile'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { Button } from '@/components/Button'
import { WarningIcon } from '@/assets'
import {
  formatTime,
  getLangData,
  getMediaSrc,
  getSearchParams
} from '@/utils/utils'
import useQuery from '@/hooks/useQuery'
import { UserAPI } from '@/services/userAPI'
import { DefaultNFT, DetailBox } from '../image'
import { useTranslation } from 'react-i18next'

const NFTDetail: React.FC = () => {
  const params = getSearchParams()
  const { data } = useQuery(() =>
    UserAPI.getNFTDetail({
      id: params.id,
      tokenId: params.tokenId
    })
  )

  const history = useHistory()
  const { t } = useTranslation()

  const name = getLangData(data?.goodsNameI18n)
  const src = getMediaSrc(data?.resources[0]?.resourceUrl) || DefaultNFT
  const chainName = data
    ? data.tokenId === 0
      ? t('profile.onChain')
      : data.chainName
    : '-'

  return (
    <Page navigator={NFT_MANAGE}>
      <Module>
        <div className={styles.header}>
          <GoBackTitle
            title={name}
            onClick={() => history.push('/profile/nft')}
          />
          {/* <Button className={styles.btn}>转出NFT</Button> */}
        </div>
        <div className={styles.detail}>
          <div className={styles.posterCtn}>
            <img src={DetailBox} className={styles.locationIcon} />
            <div className={styles.box}>
              <img className={styles.poster} src={src} />
            </div>
          </div>
          <div className={styles.infoCtn}>
            <div className={styles.label}>{t('profile.basicInfo')}</div>
            <div className={styles.title}>{name}</div>
            {/* <div className={styles.line}>
              <div className={styles.label}>兑换价格</div>
              <div>
                {data ? `${data.exchangePrice} ${t('gifr.pick')}` : '-'}
              </div>
            </div> */}
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.quantity')}</div>
              <div>{data?.quantityHeld ?? '-'}</div>
            </div>
            {/* <div className={styles.line}>
              <div className={styles.label}>兑换时间</div>
              <div>{data ? formatTime(data?.createdTime) : '-'}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>订单编号</div>
              <div>{data?.orderSn ?? '-'}</div>
            </div> */}
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.network')}</div>
              <div>{chainName ?? '-'}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.tokenId')}</div>
              <div>{params.tokenId}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.contractAddress')}</div>
              <div>{data?.contractAddress ?? '-'}</div>
            </div>
          </div>
        </div>
        <div className={styles.tipCtn}>
          <img src={WarningIcon} className={styles.warningIcon} />
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: getLangData(data?.goodsDescribeI18n)
            }}
          ></div>
        </div>
      </Module>
    </Page>
  )
}

export default React.memo(NFTDetail)
