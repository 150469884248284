import { create } from 'zustand'

type Fn = () => void

type State = { eventsMap: Record<string, Fn[]> }

type Actions = {
  registerEvent: (type: string, fn: Fn) => void
  execEvents: (type: string) => void
  clearEvents: (type: string) => void
}

const useEvents = create<State & Actions>((set, get) => ({
  eventsMap: {},
  registerEvent(type, fn) {
    const map = get().eventsMap
    const events = map[type] ?? []
    events.push(fn)
    set((state) => {
      return {
        eventsMap: {
          ...state.eventsMap,
          [type]: events
        }
      }
    })
  },
  async execEvents(type) {
    const map = get().eventsMap
    const events = map[type] ?? []
    for (let i = 0; i < events.length; i++) {
      const event = events[i]
      await event()
    }
  },
  clearEvents(type) {
    const map = get().eventsMap
    delete map[type]
    set(() => {
      return {
        eventsMap: map
      }
    })
  }
}))

export { useEvents }

export const GLOBAL_CLICK_TYPE = 'GLOBAL_CLICK'

export const DAZZLE_DETAIL_LEAVE = 'DAZZLE_DETAIL_LEAVE'
